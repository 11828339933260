@use 'sass:color';
@import "mixins/breakpoints";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition) {
  -moz-transition: all $transition ease;
  -o-transition: all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: all $transition ease;
  transition: all $transition ease;
}

body {
  font-family: $font-primary;
  background: $white;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: color.adjust($black, $lightness: 40%);

  &.menu-show {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

a {
  transition: .3s all ease;
  color: $brand-primary;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $brand-primary;
    outline: none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  color: rgba(0, 0, 0, .8);
  font-weight: 400;
}

p {
  font-size: 14px;
  line-height: 24px;
}

p.lead {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

p:last-child {
  margin-bottom: 0;
}

// SECTIONS
.ftco-section {
  padding: 6em 0;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding: 6em 0;
  }
}

.ftco-footer {
  font-size: 16px;
  padding: 7em 0;
  background: $brand-primary;
  color: rgba(255, 255, 255, .9);

  .ftco-footer-logo {
    text-transform: uppercase;
    letter-spacing: .1em;
  }

  .ftco-footer-widget {
    h2 {
      font-weight: normal;
      color: $white;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 600;
    }

    ul {
      li {
        margin-bottom: 10px;

        a {
          color: rgba(255, 255, 255, .8);

          span {
            color: $white;
          }
        }
      }
    }
  }

  p {
    color: rgba(255, 255, 255, .9);
  }

  a {
    color: $brand-primary;

    &:hover {
      color: $brand-primary;
    }
  }

  .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: $black;
  }
}

// TABULATION
.nav-pills {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;

  li {
    display: inline-block;
    font-weight: 500;

    a {
      margin-right: 2px;
      color: rgba(0, 0, 0, .3);
      border: 1px solid rgba(0, 0, 0, .1);

      &.active {
        background: $brand-primary !important;
        color: $white !important;
        border: 1px solid $brand-primary;
      }
    }
  }
}

.pills {
  margin-top: 40px;

  .tab-pane {
    padding: 2em;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
  }

  .features {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      span {
        font-size: 20px;
        margin-right: 15px;
      }

      &.check {
        span {
          color: $brand-primary;
        }

      }

      &.remove {
        span {
          color: red;
        }
      }
    }
  }

  .head {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
  }

  .review {
    width: 100%;
    margin-bottom: 30px;

    .user-img {
      width: 80px;
      height: 80px;
      @include border-radius(50%);
    }

    .desc {
      width: calc(100% - 100px);
      margin-left: 30px;

      h4 {
        width: 100%;
        margin-bottom: 10px;

        span {
          width: 49%;
          display: inline-block;

          &:first-child {
            font-size: 16px;
          }

          &:last-child {
            font-size: 13px;
            color: color.adjust($black, $lightness: 70%);
          }
        }
      }

      .star {
        width: 100%;

        span {
          width: 49%;
          display: inline-block;
        }

        i {
          color: $secondary;
        }

        .reply {
          padding: 2px 10px;
          background: color.adjust($black, $lightness: 94%);
          @include border-radius(2px);

          i {
            color: color.adjust($black, $lightness: 50%);
            font-size: 12px;
          }
        }
      }
    }
  }

  .wrap {
    padding: 2em;
    background: color.adjust($black, $lightness: 98%);
  }

  .rating-wrap {
    width: 100%;
    display: block;

    .star {
      display: block;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: inline-block;
        color: color.adjust($black, $lightness: 70%);

        i {
          color: $brand-primary;
        }

        &:first-child {
          width: 62%;
        }

        &:last-child {
          width: 32%;
        }
      }
    }
  }
}

// PAGINATION
.block-27 {
  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-bottom: 4px;
      font-weight: 400;

      a, span {
        color: $brand-primary;
        text-align: center;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        border: 1px solid color.adjust($black, $lightness: 90%);
      }

      &.active {
        a, span {
          background: $brand-primary;
          color: $white;
          border: 1px solid transparent;
        }
      }
    }
  }
}

// MOUSE WHEEL
.mouse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  z-index: 2;
}

.mouse-icon {
  width: 38px;
  height: 38px;
  border: 1px solid $brand-primary;
  @include border-radius(50%);
  background: $brand-primary;
  cursor: pointer;
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.mouse-wheel {
  margin: 2px auto 0;
  display: block;
  background: transparent;
  @include border-radius(50%);
  color: $white;
  font-size: 18px;
}

// SEARCH CAMPING
.search-wrap-1 {
  position: relative;
}

.search-property-1 {
  .form-group {
    position: relative;

    label {
      font-weight: 400;
      font-size: 18px;
      color: $black;
    }

    .form-field {
      position: relative;
      display: block;
    }

    .form-control {
      padding: 10px 40px 10px 20px;
      display: block;
      width: 100%;
      height: inherit !important;
      font-size: 16px;
      border: 1px solid rgba(0, 0, 0, .05);
      color: rgba(0, 0, 0, .4) !important;
      background: transparent !important;
      @include border-radius(4px);

      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, .4) !important;
      }

      &::-moz-placeholder {
        color: rgba(0, 0, 0, .4) !important;
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, .4) !important;
      }

      &:-moz-placeholder {
        color: rgba(0, 0, 0, .4) !important;
      }

      &.btn {
        padding: 22px 10px !important;
        font-size: 20px;
        background: $brand-primary !important;
        border: none !important;
        color: $white !important;
      }
    }

    .select-wrap {
      position: relative;

      select {
        appearance: none;
      }
    }

    .icon {
      position: absolute;
      top: 51%;
      right: 20px;
      font-size: 16px;
      transform: translateY(-50%);
      color: rgba(0, 0, 0, .4);

      @include media-breakpoint-down(sm) {
        right: 10px;
      }
    }
  }
}

// CAMING
.property-wrap {
  margin-bottom: 60px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }

  .img {
    display: block;
    width: 100%;
    height: 250px;
    position: relative;

    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $brand-primary;
      color: $white;
      font-size: 20px;
      opacity: 0;
      @include transition(.3s);
    }

    .list-agent {
      position: absolute;
      bottom: 5px;
      left: 10px;
      right: 10px;
      opacity: 0;
      @include transition(.5s);

      .agent-info {
        display: block;
        width: 100%;

        .img-2 {
          width: 40px;
          height: 40px;
        }

        h3 {
          color: $white;
          font-size: 18px;
        }
      }

      .tooltip-wrap {
        a.icon-2 {
          width: 40px;
          height: 40px;
          display: inline-block;
          margin-left: 10px;
          border: 1px solid rgba(255, 255, 255, .4);

          span {
            font-size: 20px;
            color: $white;
          }
        }
      }
    }
  }

  .text {
    position: relative;
    @include transition(.3s);

    h3 {
      font-size: 18px;

      a {
        color: $black;
        font-weight: 600;
      }
    }

    .location {
      color: rgba(0, 0, 0, .4);

      i {
        color: rgba(0, 0, 0, .4);
      }
    }

    & {
      background: $white;
      -webkit-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
      -moz-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
      box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
      width: 100%;
      padding: 25px;
    }

    p.price {
      span {
        font-size: 16px;
        font-weight: 600;
      }

      span.orig-price {
        color: $brand-primary;
      }

      span.old-price {
        color: rgba(0, 0, 0, .2);
        position: relative;
        margin-right: 10px;

        &:after {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: color.adjust($black, $lightness: 70%);
          content: '';
        }
      }

      small {
        font-size: 14px;
        color: rgba(0, 0, 0, .4);
      }
    }

    ul {
      padding: 0;

      li {
        font-size: 16px;
        margin-right: 10px;
        display: inline-block;
        list-style: none;

        span {
          margin-right: 5px;
        }
      }
    }

    .btn-custom {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background: $brand-primary;
      border-radius: 50% 0 0 0;

      span {
        color: $white;
      }
    }
  }

  &:hover {
    .img {
      .icon {
        opacity: 1;
      }
    }

    .list-agent {
      opacity: 1;
      bottom: 15px;
    }

    .text {
      -webkit-box-shadow: 0px 5px 39px -14px rgba(0, 0, 0, 0.26);
      -moz-box-shadow: 0px 5px 39px -14px rgba(0, 0, 0, 0.26);
      box-shadow: 0px 5px 39px -14px rgba(0, 0, 0, 0.26);
    }
  }
}

// CAMPING DETAILS
.ftco-property-details {
  .services {
    .media-body {
      .icon {
        span {
          color: rgba(0, 0, 0, .8);
        }
      }

      .text {
        h3 {
          font-size: 14px !important;
          color: rgba(0, 0, 0, .4);

          span {
            display: block;
            color: $black;
          }
        }
      }
    }
  }
}

// COVER BG
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.background-image-holder,
.foreground-image-holder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
}

.parallax-image-holder {
  width: 98%;
  height: 99%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.foreground-image-holder {
  width: 98%;
  height: 99%;
}

.page-header .background-image-holder {
  height: 150%;
  top: -20%;
}

.image-container.width67 {
  width: 67%;
}

// Turbolinks
.turbo-progress-bar {
  background-color: $brand-primary;
}

// MISC
.ftco-animate {
  opacity: 0;
  visibility: hidden;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}
