// Bootstrap variables

// fonts
$font-primary: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// colors
$brand-primary: #e91c8a;
$theme-colors: (
  "primary": #e91c8a
);

// Load Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// Load Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,600,700%7CRaleway:700");

// Load FontAwesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

// Load animate.css
@import "../../vendor/stylesheets/concerto_espagne/animate.scss";

// Load JQuery Fancybox
@import "~@fancyapps/ui/dist/fancybox/fancybox";

// Load theme
@import "../../vendor/stylesheets/concerto_espagne/theme.scss";
@import "../../vendor/stylesheets/concerto_espagne/navigation.scss";
@import "../../vendor/stylesheets/concerto_espagne/forms.scss";
@import "../../vendor/stylesheets/concerto_espagne/ribbons.scss";
