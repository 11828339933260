.form-contact .inputs-wrapper {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  margin-bottom: 24px;
}

.form-contact label {
  color: #777777;
}

.form-contact input:focus,
.form-contact textarea:focus {
  outline: none;
}

.form-contact input,
.form-contact textarea {
  background: none;
  border: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 16px;
}

.form-contact input::-moz-placeholder,
.form-contact textarea::-moz-placeholder {
  color: #777777;
  opacity: 1;
}

.form-contact input:-ms-input-placeholder,
.form-contact textarea:-ms-input-placeholder {
  color: #777777;
}

.form-contact input::-webkit-input-placeholder,
.form-contact textarea::-webkit-input-placeholder {
  color: #777777;
}

.form-contact input input::-webkit-input-placeholder,
.form-contact textarea input::-webkit-input-placeholder {
  color: #777777;
  font-size: 24px;
  font-weight: 300;
  position: relative;
  top: 5px;
}

.form-contact input input:-moz-placeholder,
.form-contact textarea input:-moz-placeholder {
  color: #777777;
}

.form-contact input textarea::-webkit-input-placeholder,
.form-contact textarea textarea::-webkit-input-placeholder {
  color: #777777;
}

.form-contact input textarea:-moz-placeholder,
.form-contact textarea textarea:-moz-placeholder {
  color: #777777;
}

.form-contact input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.form-contact input[type="submit"] {
  -webkit-appearance: none;
  background: none !important;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  min-width: 190px;
  border-radius: 25px;
  display: inline-block;
  padding: 14px 28px 13px 28px;
  line-height: 1;
  border: 2px solid #e91c8a !important;
  color: #e91c8a !important;
  max-width: 155px;
  font-family: "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-contact select {
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 16px;
  color: rgba(0, 0, 0, 0.5);
  outline: none;
}

.form-contact input[type="radio"] {
  margin: 15px;
  width: auto;
}

.form-contact .input.radio_buttons {
  text-align: left;
}

.form-contact .btn:hover {
  background: none !important;
  color: #fff !important;
  border-color: #e91c8a !important;
  background-color: #e91c8a !important;
}

.form-contact .input.field_with_errors span.error {
  color: #dc3545;
}

.form-contact {
  margin-bottom: 5%;
}

.form-success,
.form-error {
  width: 100%;
  padding: 12px;
  display: none;
  margin-top: 12px;
  color: #fff;
}

.form-success {
  background-color: #58b551;
}

.form-error {
  background-color: #d94947;
}

.field-error {
  box-shadow: inset 0px 0px 10px -2px #ff0000 !important;
}

.form-loading {
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  margin: 0px auto;
  top: 50%;
  width: 30px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: pulsate 1s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
}

/*
Contact SimpleForm
*/

.estimate-form .input.contact_first_name,
.subscribe-form .input.contact_first_name {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.estimate-form .input.contact_comment {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}
