ul.menu {
  list-style: none;
}

.alt-font {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

nav hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  bottom: 2px;
}

nav.pagy-bootstrap {
  display: unset;
}

.has-dropdown {
  bottom: 3px;
}

.has-dropdown:after {
  font-family: ElegantIcons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\33";
  color: #777777;
}

.mobile-toggle {
  display: none;
}

.top-bar {
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 10;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  line-height: 0;
  top: 0;
}

.logo.logo-wide {
  max-width: 140px;
  bottom: 12px;
  margin-bottom: -6px;
}

.logo.logo-square {
  max-width: 100px;
  max-height: 60px;
  bottom: 20px;
  margin-bottom: -20px;
}

.nav-sticky .logo-wide {
  bottom: 12px !important;
}

.nav-sticky .logo-square {
  bottom: 20px !important;
}

.nav-menu {
  padding-top: 30px;
}

.nav-menu .social-icons {
  float: right;
}

.utility-menu {
  margin-top: 16px;
}

.utility-menu .utility-inner {
  padding-bottom: 16px;
}

.utility-menu .btn {
  min-width: 60px;
  padding-left: 18px;
  padding-right: 18px;
  margin-left: 8px;
  margin-right: 0px;
}

.utility-menu span {
  display: inline-block;
  position: relative;
  top: 9px;
}

.utility-menu .btn-text-only {
  min-width: 0px;
  padding: 0px 8px;
}

.overlay-bar {
  background: none;
}

.overlay-bar .has-dropdown:after {
  color: #fff;
}

.menu {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  top: 2px;
}

.menu li {
  float: left;
  margin-right: 15px;
  position: relative;
}

.menu li:last-child {
  margin-right: 0px;
}

.menu li a {
  font-family: Raleway,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: bold;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  padding-bottom: 40px;
  display: inline-block;
  line-height: 25px;
  max-width: 185px;
}

.menu li a:hover {
  color: #333333;
}

.menu li a.active {
  color: #e91c8a !important;
}

@media all and (max-width:1050px) {
  .menu li {
    margin-right: 32px;
  }

}

.top-bar .social-icons li a {
  font-size: 16px;
}

.top-bar .logo-light {
  display: none;
}

.top-bar .utility-menu .utility-inner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.overlay-bar .social-icons li a {
  color: #fff;
}

.overlay-bar .subnav li a {
  color: #777777;
}

.overlay-bar .subnav li a:hover {
  color: #333333;
}

.overlay-bar .logo-dark {
  display: none;
}

.overlay-bar .logo-light {
  display: block;
}

.overlay-bar .utility-menu span {
  color: #fff;
}

.overlay-bar .utility-menu .utility-inner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.overlay-bar .login-button {
  border-color: #fff;
  color: #fff;
}

.subnav {
  display: none;
  position: absolute;
  background: #fff;
  padding: 24px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
}

.subnav li {
  margin-right: 0px !important;
  min-width: 142px;
}

.subnav li a {
  padding-bottom: 0px !important;
  margin-bottom: 16px;
}

.subnav li:last-child a {
  margin-bottom: 0px;
}

.has-dropdown:hover .subnav {
  display: block;
}

.nav-sticky {
  position: fixed !important;
  top: 0;
}

.nav-sticky .logo {
  bottom: 22px;
}

.nav-sticky hr {
  display: none !important;
}

.top-bar.nav-sticky {
  background: rgba(255, 255, 255, 0.9);
}

.top-bar.nav-sticky .nav-menu {
  padding-top: 32px;
}

.top-bar.nav-sticky .menu li a {
  padding-bottom: 32px;
}

.top-bar.nav-sticky .subnav {
  background: rgba(255, 255, 255, 0.9);
}

.top-bar.nav-sticky .utility-menu {
  display: none;
}

.subnav-fullwidth {
  overflow: hidden;
  left: 0px;
  position: absolute;
  text-align: left;
}

.subnav-fullwidth h6 {
  margin-bottom: 24px !important;
}

.subnav-fullwidth .subnav {
  position: relative;
  overflow: hidden;
  background: none !important;
  text-align: left;
  padding: 0px;
}

.subnav-fullwidth .subnav li {
  float: none !important;
}

.subnav-fullwidth .btn {
  padding-bottom: 13px !important;
  color: #e91c8a !important;
}

.subnav-fullwidth .btn {
  margin-bottom: 16px;
}

.subnav-fullwidth .btn:hover {
  color: #fff !important;
}

.subnav-fullwidth .btn-filled,
.subnav-fullwidth .btn-filled:hover {
  color: #fff !important;
}

.overlay-bar.nav-sticky .logo-light {
  display: none;
}

.overlay-bar.nav-sticky .logo-dark {
  display: block;
}

.overlay-bar.nav-sticky .menu li a {
  color: #777777;
}

.overlay-bar.nav-sticky .social-icons li a {
  color: #333333;
}

.overlay-bar.nav-sticky .has-dropdown:after {
  color: #333333;
}

.offscreen-menu .logo {
  padding-bottom: 32px;
}

.offscreen-toggle i {
  font-size: 36px;
  cursor: pointer;
}

.nav-sticky .offscreen-toggle i {
  color: #333333 !important;
}

.offscreen-container {
  position: fixed;
  width: 300px;
  background: #333333;
  right: 0px;
  height: 100%;
  top: 0px;
  z-index: 99999;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-transform: translate3d(300px, 0, 0);
  -moz-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0);
  padding: 40px;
}

.offscreen-container.reveal-nav {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.offscreen-menu .container {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.offscreen-menu .container.reveal-nav {
  -webkit-transform: translate3d(-300px, 0, 0);
  -moz-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
}

@media all and (max-width:768px) {
  .offscreen-menu {
    overflow: visible !important;
    position: relative !important;
  }

  .offscreen-menu .offscreen-toggle i {
    color: #333333;
  }

}

.offscreen-container .logo {
  bottom: 0px !important;
}

.offscreen-container .menu {
  display: block;
}

.offscreen-container .menu li {
  float: none;
  margin-bottom: 16px;
}

.offscreen-container .menu li a {
  padding-bottom: 0px !important;
  color: #aaa !important;
}

.offscreen-container .menu li a:hover {
  color: #fff !important;
}

.offscreen-container .social-icons {
  position: absolute;
  bottom: 40px;
}

.offscreen-container .social-icons li a {
  color: #fff !important;
}

.language {
  display: inline-block;
  margin-left: 8px;
}

.language img {
  max-width: 40px;
}

.overlay:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  content: '';
  opacity: 0.9;
  z-index: 2;
  background-color: #e91c8a;
  left: 0;
}

.overlay .container {
  position: relative;
  z-index: 3;
}

header {
  position: relative;
  overflow: hidden;
}

header .container {
  position: relative;
  z-index: 3;
}

header .logo {
  max-width: 200px;
  display: inline-block;
  margin-bottom: 24px;
}

header .btn {
  margin-right: 16px;
}

header .btn:last-child {
  margin-right: 0px;
}

header.fullscreen-element {
  height: 800px;
}

header.fullscreen-element .overlay:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  content: "";
  opacity: .3;
  z-index: 2;
  background-color: #333;
}

.page-header {
  margin: 0px;
  padding: 0px;
  padding: 100px 0px;
  border-bottom: none;
}

.page-header h1 {
  font-size: 40px;
  margin-bottom: 24px;
  line-height: 44px;
  font-weight: 300;
}

.page-header p.lead {
  font-size: 18px;
}

.page-header:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  content: '';
  opacity: 0.3;
  z-index: 2;
  background-color: #333333;
}

@media only screen and (min-width:320px) and (max-width:767px) {
  .top-bar {
    position: absolute;
  }

  .container.align-vertical.mainContainerAlign {
    height: 400px;
  }

  .utility-menu {
    display: none;
  }

  nav {
    max-height: 80px;
    overflow: hidden;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
  }

  nav .logo {
    margin-bottom: 32px;
  }

  nav .columns {
    width: 100%;
  }

  nav .social-icons {
    display: none;
  }

  .open-nav {
    max-height: 8000px;
  }

  .nav-menu {
    padding-top: 24px;
  }

  .top-bar .logo {
    bottom: 0px;
  }

  .top-bar .logo.logo-wide {
    bottom: 3px;
    margin-bottom: 22px;
  }

  .mobile-toggle {
    font-size: 26px;
    display: block;
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    color: #333333;
  }

  .overlay-bar {
    background: rgba(255, 255, 255, 0.9);
  }

  .overlay-bar .logo-light {
    display: none;
  }

  .overlay-bar .logo-dark {
    display: block;
  }

  .top-bar .menu li a {
    color: #777777;
    padding-bottom: 0px;
  }

  .overlay-bar .menu li a:hover {
    color: #333333;
  }

  .overlay-bar .has-dropdown:after {
    color: #333333;
  }

  .has-dropdown {
    bottom: 0px;
  }

  .menu {
    top: 0px;
  }

  .menu li {
    float: none;
    margin-bottom: 16px;
    margin-right: 0px;
  }

  .subnav {
    position: relative;
    background: none;
  }

  .subnav li {
    margin-bottom: 0px;
  }

  .subnav .col-md-3 {
    margin-bottom: 32px;
  }

  .offscreen-container .social-links {
    display: none;
  }

  .offscreen-container .logo {
    display: none;
  }

  section,
  header {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .no-align-mobile {
    padding-top: 0px !important;
    margin-top: 24px;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
  }

  p.lead {
    font-size: 14px;
    line-height: 24px;
  }

  .action-banner h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .btn {
    margin-top: 16px;
  }

  header.signup {
    height: auto;
  }

  .header-icons {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  header.title {
    padding: 0px;
    height: 400px;
  }

  .page-header br {
    display: none;
  }

  .page-header h1 {
    font-size: 32px;
    line-height: 40px;
  }

  header.centered-text h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .header-icons h2 {
    margin-bottom: 32px;
  }

  .bottom-band {
    display: none;
  }

  .side-image .content {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .no-pad-left {
    padding-left: 15px;
  }

  .row-wrapper {
    border-bottom: none;
  }

  .row-wrapper .col-sm-3 {
    border-right: none;
  }

  .strip {
    padding: 40px 0px;
  }

  .strip,
  .strip .text-right {
    text-align: center;
  }

  .strip .col-xs-12 {
    margin-bottom: 16px;
  }

  .inline-video-wrapper {
    margin-top: 40px;
  }

  .video-wrapper {
    display: none;
  }

  .inline-image-right img {
    display: inline-block;
    margin-top: 40px;
  }

  footer.social .social-icons li,
  .contact-photo .social-icons li {
    margin-right: 8px;
  }

  footer.social .social-icons li a,
  .contact-photo .social-icons li a {
    font-size: 24px;
  }

  footer.details .col-sm-4 {
    margin-bottom: 16px;
  }

  footer.details h1,
  footer.details .logo {
    margin-bottom: 32px;
  }

  footer.short-2 {
    padding-bottom: 0px;
  }

  footer.short-2 span {
    margin-right: 16px;
  }

  footer.short-2 .contact-action {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 80px;
  }

  footer.short-2 .align-vertical {
    padding-top: 64px !important;
  }

  .filters {
    padding: 0px 15px;
    text-align: center;
  }

  .filters li {
    margin: 0;
    width: 100%;
    margin-bottom: 8px;
  }

  .image-gallery .image-holder {
    height: 225px;
  }

  .hero-divider {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .hero-divider .btn {
    margin-right: 0px;
  }

  .fullscreen-element {
    height: auto !important;
    padding-top: 64px;
    padding-bottom: 64px;
  }

}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
  .hero-divider {
    height: auto;
    padding: 80px 0px 90px 0px;
  }

}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .top-bar {
    position: absolute;
  }

  .utility-menu {
    display: none;
  }

  nav {
    max-height: 80px;
    overflow: hidden;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
  }

  nav .logo {
    margin-bottom: 32px;
  }

  nav .columns {
    width: 100%;
  }

  nav .social-icons {
    display: none;
  }

  .open-nav {
    max-height: 8000px;
  }

  .nav-menu {
    padding-top: 24px;
  }

  .top-bar .logo {
    bottom: 0px;
  }

  .top-bar .logo.logo-wide {
    bottom: 10px;
    margin-bottom: 22px;
  }

  .mobile-toggle {
    font-size: 32px;
    display: block;
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    color: #333333;
  }

  .overlay-bar {
    background: rgba(255, 255, 255, 0.9);
  }

  .overlay-bar .logo-light {
    display: none;
  }

  .overlay-bar .logo-dark {
    display: block;
  }

  .overlay-bar .menu li a {
    color: #777777;
    padding-bottom: 0px;
  }

  .overlay-bar .menu li a:hover {
    color: #333333;
  }

  .overlay-bar .has-dropdown:after {
    color: #333333;
  }

  .has-dropdown {
    bottom: 0px;
  }

  .menu {
    top: 0px;
  }

  .menu li {
    float: none;
    margin-bottom: 16px;
    margin-right: 0px;
  }

  .subnav {
    position: relative;
    background: none;
  }

  .subnav li {
    margin-bottom: 0px;
  }

  .subnav .col-md-3 {
    margin-bottom: 32px;
  }

  .hidden-sm {
    display: block !important;
  }

  .hidden-sm:first-child {
    display: none !important;
  }

  .hero-divider {
    height: auto;
    padding: 80px 0px 90px 0px;
  }

  footer.details .social-icons li {
    margin-bottom: 16px;
  }

  footer.short-2 {
    padding-bottom: 0px;
  }

  footer.short-2 span {
    margin-right: 32px;
  }

  footer.short-2 .contact-action {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 80px;
  }

  footer.short-2 .align-vertical {
    padding-top: 64px !important;
  }

}

@media (min-width:576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }

}

@media (min-width:768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }

}

@media (min-width:992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }

}

@media (min-width:1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }

}

.logoImage {
  background-repeat: no-repeat;
}

.logoImage {
  background-image: url('../../images/concerto/logos/spanish-site.png');
  width: 130px;
  height: 54px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -20px;
}

.home-container {
  background-image: url('../../images/concerto/halo.png');
  background-position: 50% 10%;
  background-repeat: no-repeat;
  height: 750px;
  padding-top: 300px;
}

.home-container h1 {
  font-size: 28px;
  margin-bottom: 33px;
  line-height: 40px;
  font-weight: 300;
}

section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
}

section:nth-of-type(odd) {
  background: #f4f4f4;
}

section:nth-of-type(2n) {
  background: #fff;
}
