.ribbon-wrapper {
  position: absolute;
  display: block;
  z-index: 900;
  top: 5px;
  left: -11px;
}

.ribbon-wrapper > .ribbon {
  position: relative;
  display: block;
  margin-bottom: 10px;
  padding: 7px 12px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #e91c8a;
}

.ribbon-wrapper > .ribbon::after {
  position: relative;
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 7.5px 0 7.5px;
  border-color: #ffffff transparent transparent transparent;
  border-top-color: #e91c8a;
  position: absolute;
  display: block;
  z-index: -1;
  bottom: -6px;
  left: 3px;
  top: auto;
}
